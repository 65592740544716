
@import "./src/assets/style/variables";
.docs-needed {
  color: $blue-03;
  text-decoration: underline;
  position: relative;
  padding-right: 3.4rem;
  &:hover {
    text-decoration: none;
  }
  &:after {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    right: 0;
    content: "";
    top: 0;
    background: url("/pic/profile/arrow-up-right.svg");
  }
}
