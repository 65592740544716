
.section__lk-order-info {
  max-width: 30%;
}
.section__lk-order-info__security {
  font-size: 1.8rem;
  line-height: 3.2rem;
  p, ul li {
    line-height: 3.2rem;
  }
  li::marker {
    font-size: .5em;
  }
  @media (max-width: 576px) {
    font-size: 18px;
    line-height: 32px;
    p, ul li {
      line-height: 32px;
      font-size: 18px;
    }
    li::marker {
      font-size: 5px;
    }
  }
}
